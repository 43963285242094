.tree-container > svg {
  width: 100vmax;
  height: 100vmax;
}

.tree-container > svg > g > foreignObject {
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
  border-radius: 4px;
  height: 100%;
}

.linage {
  fill: none;
  stroke: #605e5c;
  stroke-width: 1px;
}
.marriage {
  fill: none;
  stroke: #605e5c;
  stroke-width: 4px;
  stroke-dasharray: 4;
}
