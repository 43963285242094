.person-node-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px 12px 20px 20px;
  height: 100%;
  border-radius: 4px;
  background-color: white;
}

/* Safari */
@media not all and (min-resolution: 0.001dpcm) {
  .person-node-container {
    border: 1px solid #393939;
  }
}

.person-node-container-with-connection {
  border: 2px dashed black;
}

.person-basic-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.man .person-image {
  border: 3px solid #0087ef;
}
.woman .person-image {
  border: 3px solid #f03861;
}

.man .person-expand-arrow {
  color: #0087ef;
}
.woman .person-expand-arrow {
  color: #f03861;
}

.person-image {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  width: 90px;
  height: 90px;
  margin-right: 12px;
  background-color: #edebe9;
  border-radius: 50%;
  overflow: hidden;
}

.person-avatar {
  font-size: 42px;
  color: #323130;
}

.person-right-side {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.person-name {
  font-size: 20px;
  font-weight: 600;
}

.person-info-line {
  font-size: 14px;
  margin-top: 3px;
}

.person-action {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.person-expand-arrow {
  font-size: 28px;
  cursor: pointer;
}

.person-expanded {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  font-size: 12px;
  animation: slideDownMargin 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
}

.person-detail {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.person-detail-icon {
  color: #323130;
  font-size: 20px;
  margin-right: 3px;
}

.person-detail-value {
  margin-left: 5px;
  font-size: 14px;
  font-weight: 600;
}

.person-tree-connection {
  padding: 3px;
  font-size: 24px;
  margin-bottom: 3px;
}

.person-tree-connection:hover {
  background-color: #edebe9;
}

@keyframes slideDownMargin {
  0% {
    margin-top: -8px;
  }
  100% {
    transform: 16px;
  }
}
