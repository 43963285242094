.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}

.header {
  background-color: #393939;
  color: white;
  font-size: 24px;
  font-weight: 600;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-icon {
  color: white;
  font-size: 32px;
  margin-right: 6px;
}

.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 24px;
  color: white;
  background-color: #393939;
  padding: 18px;
  position: absolute;
  bottom: 12px;
  right: 12px;
}
